import React from "react";
import styles from "./MediaCard.module.css";
import NameCardA from "../NameCardA/NameCardA.controller";
import EllipsizedText from "@/mvcComponents/Commons/EllipsizedText/EllipsizedText.controller";
import MediaRenderer from "@/mvcComponents/Commons/MediaRenderer/MediaRenderer.view";

const MediaCard = ({
  imageHeight = 200,
  section_id,
  heading,
  subHeading,
  image,
  link,
  Media,
  styleOverride = null,
  text,
  setTextAsHtml = true,
}) => {
  let { url = "", alt = "" } = Media || {};
  return (
    <div className={styles.root}>
      <NameCardA
        section_id={section_id}
        heading={heading}
        subHeading={subHeading}
        image={image?.url}
        link={link}
        icon="FaLinkedin"
      />
      {text && (
        <EllipsizedText
          defaultHeight={60}
          textAlign="left"
          showSeeMore
          text={text}
          setTextAsHtml={setTextAsHtml}
        />
      )}
      <MediaRenderer
        image={Media}
        height={imageHeight}
        width={imageHeight}
        styleOverride={styleOverride}
        alt={alt}
        enlargeImageOnClick={true}
      />
    </div>
  );
};

export default MediaCard;
