import React from "react";
import styles from "./MediaB.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import MediaCard from "../../Cards/MediaCard/MediaCard.view";
import { alignmentMapping } from "@/staticData/constant";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MediaBView = ({
  heading = "",
  subHeading = "",
  ctaText = "",
  ctaLink = "",
  section_id = "",
  listData = [],
  alignment = "left",
}) => {
  return (
    <section
      id={section_id}
      data-outscal-section
      className={styles.root}
      style={{ alignItems: alignmentMapping[alignment] }}
      view-analytics={[UIElements.CARD, `media-${section_id}`]}
    >
      {heading && (
        <h2
          className={styles.heading}
          style={{ textAlign: alignmentMapping[alignment] }}
        >
          {heading}
        </h2>
      )}
      {subHeading && (
        <p
          className={styles.subHeading}
          style={{ textAlign: alignmentMapping[alignment] }}
        >
          {subHeading}
        </p>
      )}
      <div
        className={styles.flexgrid}
        style={{ justifyContent: alignmentMapping[alignment] }}
      >
        {listData?.map((item) => {
          let {
            height = 200,
            heading = "",
            image = {},
            Media = {},
            subHeading = "",
            link = "",
            text = "",
            setTextAsHtml = true,
          } = item || {};
          return (
            <MediaCard
              text={text}
              imageHeight={height}
              section_id={section_id}
              heading={heading}
              subHeading={subHeading}
              image={image}
              link={link}
              Media={Media}
              setTextAsHtml={setTextAsHtml}
              styleOverride={{
                width: "100%",
                height: height,
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          );
        })}
      </div>
      {ctaLink && ctaText && (
        <ConditionLinkView link={ctaLink} section_id={section_id}>
          <div className={styles.cta}>{ctaText}</div>
        </ConditionLinkView>
      )}
    </section>
  );
};

export default MediaBView;
